import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import smile from "../assets/icons8-smiling-face-with-smiling-eyes-94.webp";
import sad from "../assets/icons8-disappointed-94.webp";
import sampleImg from "../assets/SampleBackgroundImage.webp";
import { useLocation, useNavigate } from "react-router";
import { PostUserSession } from "../api/PostUserSessionAPI";
import { setShowWheelPopup, setUser } from "../store/slice/CustomizeSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const { REACT_APP_S3_URL } = process.env;
interface WheelPopUpProps {
  segment: any;
}

interface Segment {
  id: string;
  prize: string;
  weight: number;
  color: string;
  textColor: string;
  isWin: boolean;
}

interface User {
  name: string;
  email: string;
  prize: string;
}

const WheelPopUp: React.FC<WheelPopUpProps> = ({ segment }) => {
  const {
    user,
    secondaryColor,
    textColor,
    backgroundColor,
    fontFamily,
    preview,
    backgroundIMG,
  } = useSelector((state: RootState) => state.customize);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const gameId = queryParams.get("gameId") || sessionStorage.getItem("gameId");

  const handleSubmit = async (gameId: string, user: User, segment: Segment) => {
    try {
      const data = await PostUserSession(gameId, user, segment);

      if (data.success === true) {
        localStorage.setItem("player", user.email);
      }
    } catch (error: any) {
      dispatch(
        setUser({
          ...user,
          name: "",
          email: "",
          prize: "",
        }),
      );
    }
  };

  useEffect(() => {
    if (gameId && segment) {
      handleSubmit(gameId, user, segment);
    }
  }, [segment.prize]);

  return (
    <div className="fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-80 transition">
      <div className="z-10 flex h-screen w-screen bg-transparent backdrop-blur-sm"></div>
      <div
        style={{
          backgroundColor: backgroundColor,
          backgroundImage:
            backgroundIMG !== "default" && backgroundIMG !== "none"
              ? `url(${REACT_APP_S3_URL}/spin-the-wheel/background/${backgroundIMG})`
              : backgroundIMG !== "none"
                ? `url(${sampleImg})`
                : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          WebkitTextFillColor: textColor,
        }}
        className="CardShadow absolute left-1/2 top-1/2 z-20 flex h-96 w-72 -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-y-1 rounded-xl bg-opacity-95 p-3 md:w-96"
      >
        <div>
          <img
            src={segment.isWin ? smile : sad}
            className="h-24 sm:h-28"
            alt="win"
          />
        </div>
        <p
          style={{ fontFamily: fontFamily }}
          className="text-2xl font-semibold text-white"
        >
          {segment.isWin ? "Congratulations!" : "Opps !"}
        </p>
        <p
          style={{ fontFamily: fontFamily }}
          className="mb-1 px-4 text-center text-xl font-extrabold text-white"
        >
          {segment?.prize}
        </p>

        <button
          style={{
            backgroundColor: backgroundIMG ? secondaryColor : backgroundColor,
            WebkitTextFillColor: textColor,
            fontFamily: fontFamily,
          }}
          onClick={() => {
            setTimeout(() => {
              if (preview) {
                dispatch(setShowWheelPopup(false));
              } else {
                navigate(-1);
                dispatch(setShowWheelPopup(false));
              }
            }, 1000);
          }}
          className="ButtonShadow lg:text-l w-40 cursor-pointer rounded-full px-3 py-4 text-sm font-semibold uppercase"
        >
          {" "}
          ok
        </button>
      </div>
    </div>
  );
};

export default WheelPopUp;

import axios, { AxiosResponse } from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

interface User {
  name: string;
  email: string;
  prize: string;
}

// Ensure ApiResponse is exported
export interface ApiResponse {
  success: boolean;
  message?: string;
}

// Export the GetUserSession function
export const GetUserSession = async (
  gameId: string,
  user: User
): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.get(
      `${REACT_APP_API_URL}/game/spin-the-wheel/check-user/${gameId}?email=${user.email}`
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

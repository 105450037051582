import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { setSegments, setCollectUserInfo } from "../store/slice/CustomizeSlice";
import { FaChevronDown } from "react-icons/fa";
import { Switch } from "@headlessui/react";
import { Check } from "react-feather";

interface InteractivityProps {}

const options = [
  { name: "Win", value: true },
  { name: "Lose", value: false },
];

const Interactivity: React.FC<InteractivityProps> = ({}) => {
  const dispatch = useDispatch();
  const {
    segments,

    collectUserInfo,
  } = useSelector((state: RootState) => state.customize);

  const handleItemClick = (option: string, segmentId: string) => {
    const newSegments = segments.map((segment) => {
      if (segment.id === segmentId) {
        return {
          ...segment,
          isWin: option === "Win" ? true : false, // Convert string value to boolean
        };
      }
      return segment;
    });
    dispatch(setSegments(newSegments));
  };

  const handleUserInfo = () => {
    dispatch(setCollectUserInfo(!collectUserInfo));
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);

  const toggleDropdown = (id: number) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="interactivity flex flex-col gap-y-2 pb-[5rem] xl:pb-5">
      <div className="mb-2 flex w-full flex-row items-start justify-between">
        <div className="flex w-full flex-col justify-between">
          <h2 className="block text-base font-semibold">
            Collect only winners Data
          </h2>
          <p className="mt-1 w-64 text-[13px] text-gray-500">
            We will collect both winners and losers details like name and email
            by default.
          </p>
        </div>
        <div>
          <Switch
            checked={collectUserInfo}
            onChange={handleUserInfo}
            className={`${collectUserInfo ? "bg-blue-500" : "bg-gray-300"} relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                collectUserInfo ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>
      <div className="flex flex-col text-left">
        <p
          id="dropdownDefaultButton"
          className="mb-2 block text-base font-semibold"
        >
          Select Win or lose secenario for each slice
        </p>

        <div className="z-10 flex w-full flex-col justify-start divide-y divide-gray-100 rounded-lg p-0">
          <ul
            className="m-0 flex w-full flex-col items-start justify-start gap-y-2 p-0 py-2 text-sm"
            aria-labelledby="dropdownDefaultButton"
          >
            {segments.map((segment, index) => (
              <li
                className="flex w-full flex-col justify-start gap-y-4 rounded-lg"
                key={segment.id}
              >
                <div className="flex h-12 items-center justify-between gap-x-3">
                  <p className="w-full rounded-md bg-[#f5f5f6] p-3 text-start text-[15px]">
                    {segment.prize}
                  </p>
                  <div className="relative w-auto">
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="flex h-10 w-20 items-center justify-between rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 text-left text-sm shadow-sm outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <span className="truncate text-[15px]">
                        {segment.isWin ? "Win" : "Lose"}
                      </span>
                      <FaChevronDown
                        className={`h-3 transition-transform ${openDropdownId === index ? "rotate-180" : ""}`}
                      />
                    </button>

                    {openDropdownId === index && (
                      <div
                        ref={dropdownRef}
                        className={`absolute ${segments.length - 1 === index ? "bottom-10" : ""} text-[15px] z-50 mt-2 h-auto w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg`}
                      >
                        {options.map((option) => (
                          <div
                            key={option.name}
                            className="flex cursor-pointer items-center  justify-between px-4 py-2 hover:bg-gray-100"
                            onClick={() => {
                              handleItemClick(option.name, segment.id);
                              toggleDropdown(index);
                            }}
                          >
                            <span>{option.name}</span>
                            {option.value === segment.isWin && (
                              <span className="h-4 ml-1.5 text-blue-800">✓</span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Interactivity;

// store.ts
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import customizeReducer from "./slice/CustomizeSlice"; // Adjust the path as needed

// Persist configuration
const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, customizeReducer);

// Configure the store
const store = configureStore({
  reducer: {
    customize: persistedReducer,
  },
});

// Create a persistor
export const persistor = persistStore(store);

// Define RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Define AppThunk type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;

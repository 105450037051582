import axios from "axios";
import { AppThunk } from "../store/store";
import toast from "react-hot-toast";
import { setGameID, setDone } from "../store/slice/CustomizeSlice";

const { REACT_APP_API_URL, REACT_APP_WEBSITE_URL } = process.env;

export const createOrUpdateCampaign =
  (
    gameId: string | null,
    token: string | null,
    ccid: string | null,
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        customize: {
          segments,
          backgroundColor,
          fontSize,
          fontFamily,
          backgroundIMG,
          title,
          description,
          collectUserInfo,
          instruction,
          instructions,
          textColor,
          secondaryColor,
          logo,
          LogoUrl,
          isdone,
          FaviconUrl,
        },
      } = getState();

      // Validate segments
      const emptySegment = segments.find((segment: any) => !segment.prize);
      if (emptySegment) {
        toast.error("Please fill the segment field.", { duration: 2000 });
        return;
      }

      let url = `${REACT_APP_API_URL}/game/spin-the-wheel`;
      let method = "post";
      let headers: { [key: string]: string } = {
        "Content-Type": "application/json",
      };

      if (gameId && token) {
        method = "patch";
        url += `/${gameId}`;
        headers["Authorization"] = "Bearer " + token;
      }

      const instructionTexts = instructions.map(
        (instruction: any) => instruction.text,
      );
      const payload = {
        segments,
        background: {
          ...(backgroundColor && { hexCode: backgroundColor }),
          textSize: fontSize,
          fontFamily: fontFamily,
          image: backgroundIMG,
        },
        logo: {
          ...(logo && { image: logo }),

          ...(LogoUrl && { websiteUrl: LogoUrl }),
        },
        title,
        description,
        winnersOnly: collectUserInfo,
        displayInstructions: instruction,
        instructions:
          instruction && instructions.length > 0 ? instructionTexts : [],
        textColor,
        secondaryColor,
        favicon: FaviconUrl,
      };

      const response = await axios({
        method,
        url,
        data: payload,
        headers,
      });

      if ([200, 201].includes(response.status)) {
        console.log(
          `Campaign ${method === "patch" ? "updated" : "created"} successfully:`,
        );
        dispatch(setGameID(response.data.data._id));
        if (ccid || gameId) {
          const successMessage =
            method === "post"
              ? "Game customized successfully"
              : "Game updated successfully";
          !isdone && toast.success(successMessage, { duration: 3000 });
          setTimeout(() => {
            if (ccid) {
              window.open(
                `${REACT_APP_WEBSITE_URL}/dashboard/campaigns/create?ccid=${ccid}&gameId=${response.data.data._id}`,
                "_self",
              );
            }
          }, 3000);
        } else {
          dispatch(setDone(true));
          return response.data;
        }
      }
    } catch (error) {
      console.error("Error creating/updating campaign:", error);
      // Dispatch an action to update the Redux store with an error state
      // Example: dispatch(setError(error.message));
      toast.error("An error occurred. Please try again later.");
    }
  };

import Game from "../Components/Game";
import CustomizationForm from "../Components/CustomizationForm";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router";

import { FaQuestion, FaRegEyeSlash, FaUser } from "react-icons/fa";
import Favicon from "../assets/WorkPlay_FaviCon (3).svg";
import { RootState } from "../store/store";
import { fetchData } from "../api/fetchGameDataAPI";
import { AppDispatch } from "../store/store";
import toast, { Toaster } from "react-hot-toast";
import { ChevronLeft, ChevronRight, Eye } from "react-feather";
import PopUp from "../Components/PopUp";
import { FaPalette, FaHandPointer, FaPencilAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import { useMediaQuery } from "react-responsive";
import { X, ArrowLeft } from "react-feather";
import Home from "./Home";
import {
  setPreview,
  setCurrentScreen,
  resetState,
  setBackgroundImg,
  setLoader,
  setCampaignID,
} from "../store/slice/CustomizeSlice";

import { createOrUpdateCampaign } from "../api/createOrUpdateAPI";
import { linkGameToUser } from "../api/linkGameToUserAPI";
import LogoLoader from "../Components/LogoLoader";
import MainSideBar from "../Components/MainSideBar";
import MobileToolBar from "../Components/MobileToolBar";
import BuiltWithWorkplay from "../Components/BuiltWithWorkplay";

interface GameCustomizeProps {}
const { REACT_APP_S3_URL, REACT_APP_WEBSITE_URL } = process.env;

const GameCustomize: React.FC<GameCustomizeProps> = ({}) => {
  const menu = [
    { name: "Specification", icon: FaPencilAlt },
    { name: "Design", icon: FaPalette },
    { name: "Interactivity", icon: FaHandPointer },
  ];

  const { spinning, isdone, preview, currentScreen, loader, CampaignID } =
    useSelector((state: RootState) => state.customize);

  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1280px)" });
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(menu[0].name);
  const [close, setClose] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [content, setContent] = useState("both");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const questionButtonRef = useRef<HTMLButtonElement>(null);
  const ConfirmRef = useRef<HTMLButtonElement>(null);
  const RestRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(location.search);
  const token =
    queryParams.get("xSudTzMYmd") || sessionStorage.getItem("xSudTzMYmd");

  if (token) {
    sessionStorage.setItem("xSudTzMYmd", token);
  }
  const source = queryParams.get("source");
  const ccid = queryParams.get("ccid");
  const gameId = queryParams.get("gameId");

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      questionButtonRef.current &&
      !questionButtonRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  const handleClickOutsidereset = (event: MouseEvent) => {
    if (
      RestRef.current &&
      !RestRef.current.contains(event.target as Node) &&
      ConfirmRef.current &&
      !ConfirmRef.current.contains(event.target as Node)
    ) {
      toast.remove();
    }
  };

  const handleContactSupport = () => {
    window.open(`${REACT_APP_WEBSITE_URL}/contact`, "_blank");
    setDropdownOpen(false);
  };

  const handleRestore = () => {
    toast.custom((t) => (
      <div className="absolute right-5 top-16 z-50 w-72 rounded-xl border border-gray-200 bg-white px-5 py-4 text-black shadow-sm drop-shadow sm:right-[89px]">
        <div className="absolute -top-2 right-28 h-0 w-0 border-b-8 border-l-8 border-r-8 border-b-white border-l-transparent border-r-transparent"></div>
        <div className="absolute -top-1 right-28 h-0 w-0 border-b-8 border-l-8 border-r-8 border-b-white border-l-transparent border-r-transparent"></div>{" "}
        <p className="mb-2 text-left text-base font-semibold">Confirm Reset</p>
        <p className="text-left text-[15px] font-medium">
          Are you sure you want to reset? You will lose all changes made.
        </p>
        <div className="mt-4 flex flex-row items-end justify-end gap-x-3">
          <button
            onClick={() => {
              toast.remove();
            }}
            className="rounded-full border border-stone-200 bg-stone-100 px-6 py-2 text-sm font-semibold text-black shadow-sm hover:opacity-80"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              dispatch(setLoader(true));
              dispatch(setBackgroundImg(""));
              setTimeout(() => {
                dispatch(resetState());
                dispatch(setLoader(false));
              }, 1500);

              if (gameId && ccid && token) {
                sessionStorage.setItem("gameId", gameId);
                sessionStorage.setItem("ccid", ccid);
                sessionStorage.setItem("xSudTzMYmd", token);
              }
              toast.remove();
            }}
            className="w-fit rounded-full bg-black px-4 py-2 text-sm font-semibold text-white hover:opacity-80"
          >
            Confirm
          </button>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    dispatch(setLoader(true));
    if (token && gameId) {
      linkGameToUser({ token, gameId });
    }

    if (ccid !== CampaignID && gameId && ccid) {
      dispatch(fetchData(gameId));
    }
    if (ccid !== CampaignID && !gameId) {
      if (ccid) {
        dispatch(resetState());
        dispatch(setCampaignID(ccid));
      }
    }

    if (source === "game") {
      dispatch(resetState());
      queryParams.delete("source");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 1500);
  }, [ccid, CampaignID]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const favicon = document.querySelector(
      "link[rel='icon']",
    ) as HTMLLinkElement;
    const originalFavicon = favicon.href;
    const originalTitle = document.title;
    document.title = "WorkPlay Studio Pvt Ltd - Spin the wheel Customization ";
    favicon.href = `${Favicon}`;
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsidereset);

    if (!isTabletOrMobile) {
      setContent("both");
    } else {
      setContent("game");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.title = originalTitle;
      favicon.href = originalFavicon;
    };
  }, [isTabletOrMobile, preview]);

  if (loader) {
    return (
      <div className="h-screen w-screen">
        <LogoLoader />
      </div>
    );
  }

  return (
    <div className="flex h-svh w-full flex-col">
      {location.pathname === "/" && !preview && (
        <div>
          <div className="flex items-center justify-between bg-primary px-6 py-5 text-[15px] text-secondary lg:py-4">
            <img
              loading="lazy"
              src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
              alt="workplay-logo"
              className="h-5 cursor-pointer lg:h-6"
              onClick={() => window.open(`${REACT_APP_WEBSITE_URL}`, "_blank")}
            />
            <div className="hidden items-center space-x-3 font-semibold lg:flex">
              <button
                data-tooltip-id="preview"
                data-tooltip-content="Preview"
                onClick={() => {
                  dispatch(setPreview(!preview));
                }}
                className="flex h-10 w-10 flex-col items-center justify-center rounded-full bg-[#ededf0]"
              >
                {!preview ? (
                  <div className="hover:scale-105">
                    <Eye className="text-black" size={18} />
                  </div>
                ) : (
                  <FaRegEyeSlash className="h-10 font-medium" />
                )}
              </button>

              <div className="ml-3 flex flex-row gap-x-3">
                {!gameId && (
                  <button
                    onClick={handleRestore}
                    className="rounded-full bg-[#f0f0f1] px-6 py-2.5 text-[15px] font-semibold text-primary transition-colors duration-300 hover:bg-[#e2e2e2]"
                  >
                    Reset
                  </button>
                )}
                <button
                  disabled={spinning}
                  onClick={() => {
                    dispatch(createOrUpdateCampaign(gameId, token, ccid));
                  }}
                  className="rounded-full bg-tertiary px-6 py-2 text-[15px] font-semibold text-secondary"
                >
                  {!gameId ? "Generate Link" : "Update Link"}
                </button>

                <Tooltip
                  id="preview"
                  place="bottom"
                  noArrow
                  offset={2}
                  delayHide={500}
                  style={{ borderRadius: "10px" }}
                ></Tooltip>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between border bg-white px-4 py-3 text-[15px] font-semibold text-secondary shadow-sm lg:hidden">
            <p className="text-black" onClick={() => navigate(-1)}>
              <ArrowLeft />
            </p>

            <div className="flex flex-row items-center justify-center">
              <button
                data-tooltip-id="preview"
                data-tooltip-content="Preview"
                onClick={() => {
                  dispatch(setPreview(!preview));
                }}
                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-secondary transition-colors duration-300 hover:bg-gray-300"
              >
                {!preview ? (
                  <Eye className="text-black" size={18} />
                ) : (
                  <FaRegEyeSlash className="font-medium" />
                )}
              </button>
              <div className="ml-2 flex flex-row gap-x-2">
                {!gameId && (
                  <button
                    onClick={handleRestore}
                    data-tooltip-id="preview"
                    data-tooltip-content="Preview"
                    className="rounded-full bg-[#f0f0f1] px-5 py-2.5 text-sm text-primary transition-colors duration-300 hover:bg-[#e2e2e2]"
                  >
                    Reset
                  </button>
                )}

                <button
                  disabled={spinning}
                  onClick={() => {
                    dispatch(createOrUpdateCampaign(gameId, token, ccid));
                  }}
                  className="rounded-full bg-tertiary px-5 py-2.5 text-sm transition-colors duration-300 hover:bg-blue-600"
                >
                  {!gameId ? " Generate Link" : "Update Link"}
                </button>
              </div>
            </div>
            <Tooltip
              id="preview"
              place="bottom"
              noArrow
              offset={10}
              delayHide={500}
              style={{ borderRadius: "10px" }}
            ></Tooltip>
          </div>{" "}
        </div>
      )}

      <div
        className={`relative flex flex-col overflow-hidden ${
          preview
            ? "m-0 h-screen w-screen p-0"
            : "size-full w-screen overflow-y-auto p-[1px] xl:flex-row xl:p-6"
        } ${close ? "" : ""} gap-x-6 gap-y-3`}
      >
        {(content === "game" || content === "both") && (
          <div
            className={`relative flex flex-col justify-between bg-white drop-shadow ${
              preview
                ? "m-0 h-screen w-screen overflow-hidden rounded-none border-0 border-none bg-transparent p-0"
                : "w-full border-[1px] border-secondary px-4 py-3 xl:w-[60.5%] xl:rounded-xl"
            } ${close ? "" : " "} items-center justify-center`}
          >
            {currentScreen === 0 ? (
              <Home />
            ) : (
              <div
                style={{
                  opacity: currentScreen,
                  transition: "opacit 0.5s ease-in-out",
                }}
                className="relative m-0 flex w-full flex-col justify-between overflow-y-auto p-0"
              >
                <Game isCutomize={true} />
                <BuiltWithWorkplay />
                {!preview && (
                  <button
                    data-tooltip-id="start screen"
                    onClick={() => dispatch(setCurrentScreen(0))}
                    className={`absolute left-6 top-1/2 flex flex-row justify-between rounded-full bg-white p-2 ${
                      currentScreen === 0 ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    <ChevronLeft size={20} />
                  </button>
                )}
                {!preview && (
                  <button
                    data-tooltip-id="game screen"
                    onClick={() => dispatch(setCurrentScreen(1))}
                    className={`absolute right-6 top-1/2 flex flex-row justify-between rounded-full bg-white p-2 ${
                      currentScreen === 1 ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    <ChevronRight size={20} />
                  </button>
                )}
                {currentScreen !== 0 && (
                  <Tooltip
                    id="start screen"
                    content="Start screen"
                    place="bottom"
                    noArrow
                    offset={10}
                    delayHide={500}
                    style={{ borderRadius: "10px" }}
                  ></Tooltip>
                )}
                {currentScreen !== 1 && (
                  <Tooltip
                    id="game screen"
                    content="Game screen"
                    place="bottom"
                    noArrow
                    offset={0}
                    delayHide={0}
                    style={{ borderRadius: "10px" }}
                  ></Tooltip>
                )}
              </div>
            )}

            {!preview && (
              <div className="">
                {currentScreen === 0 ? (
                  <div className="mt-2 w-full rounded-md bg-white p-0 py-2 pb-16 text-start xl:bg-[#f5f5f6] xl:px-4 xl:pb-2">
                    <p className="text-[15px] font-medium">
                      <span className="text-lg font-semibold">
                        Play Screen:{" "}
                      </span>
                      This is a view of the play page with the Spin title,
                      description, optional instructions, name and email fields
                      and a "PLAY" button. Click <strong>"Preview"</strong> to
                      view the game in full screen with all functionalities.
                    </p>
                    <p className="flex items-center text-[15px]"> </p>
                  </div>
                ) : (
                  <div className="mt-2 w-full rounded-md bg-white px-4 py-2 pb-16 text-start xl:bg-[#f5f5f6] xl:pb-2">
                    <p className="text-[15px] font-medium">
                      <span className="text-lg font-semibold">
                        Game Screen:{" "}
                      </span>
                      View a sample Spin the wheel and its prizes . Click{" "}
                      <strong>"Preview"</strong> to view wheel in full screen
                      with all functionalities.
                    </p>
                    <p className="flex items-center text-[15px]"> </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {preview ||
          (location.pathname === "/" &&
            (content === "customize" || content === "both") && (
              <div
                className={`w-full drop-shadow ${
                  close ? "border-none bg-transparent" : "bg-white"
                } items-center justify-center overflow-hidden border border-secondary pb-[4.25rem] md:flex-1 xl:rounded-xl`}
              >
                <div className="mx-4 mb-[1rem] mt-6 flex flex-row items-center justify-between bg-white xl:mx-8">
                  {" "}
                  <p className="text-lg font-medium">{selectedMenu}</p>
                  <button
                    onClick={() => {
                      setContent("game");
                      setSelectedMenu("");
                    }}
                    className="text-gray-600 xl:hidden"
                  >
                    <X />
                  </button>
                </div>
                {!preview && (
                  <CustomizationForm
                    selectedMenu={selectedMenu}
                    SetMenu={setSelectedMenu}
                    setClose={setClose}
                  />
                )}
              </div>
            ))}
        {!preview && (
          <div>
            <MainSideBar
              menu={menu}
              selectedMenu={selectedMenu}
              setClose={setClose}
              setSelectedMenu={setSelectedMenu}
            />
          </div>
        )}
      </div>
      {preview ||
        (location.pathname === "/" && (
          <div className="fixed bottom-[6rem] right-[1rem] mt-10 flex flex-col items-center justify-center rounded-full xl:hidden">
            <button
              ref={questionButtonRef}
              className={`rounded-full bg-primary p-[13px] hover:scale-105 ${
                dropdownOpen
                  ? "animate-pulse ring-4 ring-gray-400 ring-opacity-50"
                  : "shadow-xl"
              }`}
              onClick={toggleDropdown}
              data-tooltip-id="help"
              data-tooltip-content="Help"
            >
              <FaQuestion size={14} className="text-white" />
            </button>
            {dropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute bottom-14 right-1 w-48 rounded-xl bg-white shadow"
              >
                <div className="absolute -bottom-2 right-3 h-0 w-0 border-x-8 border-t-8 border-x-transparent border-t-white drop-shadow"></div>
                <ul onClick={handleContactSupport} className="p-2">
                  <li className="flex cursor-pointer items-center space-x-2 rounded-lg p-2 hover:bg-gray-100">
                    <FaUser size={12} />
                    <span>Contact support</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        ))}

      {!preview && (
        <MobileToolBar
          menu={menu}
          close={close}
          setContent={setContent}
          selectedMenu={selectedMenu}
          setClose={setClose}
          setSelectedMenu={setSelectedMenu}
        />
      )}
      <Toaster />
      {isdone && !ccid && <PopUp />}
    </div>
  );
};

export default GameCustomize;

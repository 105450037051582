import axios from "axios";
import { AppThunk } from "../store/store";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store"; // Adjust the path as per your project structure
import { AnyAction } from "redux";
import {
  setSegments,
  setFontSize,
  setFontFamily,
  setBackgroundColor,
  setCollectUserInfo,
  setTitle,
  setDescription,
  setTextColor,
  setSecondaryColor,
  setInstruction,
  setInstructions,
  setStart,
  setEnd,
  setBackgroundImg,
  setStyle,
  setLogo,
  setLogoUrl,
  setLoader,
  setCampaignID,
  setFaviconUrl,
} from "../store/slice/CustomizeSlice";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;
export const fetchData =
  (gameId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      sessionStorage.removeItem("updateDone");
      dispatch(setLoader(true));
      const response = await axios.get(
        `${REACT_APP_API_URL}/game/spin-the-wheel/${gameId}`,
        {
          httpsAgent: false,
        },
      );

      const responseData = response.data.data;
      dispatch(setSegments(responseData.segments));
      dispatch(setFontSize(responseData.background.textSize));
      dispatch(setFontFamily(responseData.background.fontFamily));
      dispatch(setBackgroundColor(responseData.background.hexCode));
      dispatch(setLogo(responseData.logo.image || ""));
      dispatch(setLogoUrl(responseData.logo?.websiteUrl || ""));
      dispatch(setFaviconUrl(responseData.favicon));
      dispatch(setCollectUserInfo(responseData.winnersOnly));
      dispatch(setTitle(responseData.title));
      dispatch(setDescription(responseData.description));
      dispatch(setTextColor(responseData.textColor));
      dispatch(setSecondaryColor(responseData.secondaryColor));
      dispatch(setInstruction(responseData.displayInstructions));
      dispatch(setStart(responseData.campaign.startDate));
      dispatch(setCampaignID(responseData.campaign._id));
      dispatch(setEnd(responseData.campaign.endDate));
      dispatch(
        setInstructions(
          responseData?.instructions.map((text: string, index: number) => ({
            id: index + 1,
            text,
          })),
        ),
      );
      if (response.data.data.background.image) {
        dispatch(setBackgroundImg(response.data.data.background.image));
        dispatch(
          setStyle({
            backgroundImage: `url( ${REACT_APP_S3_URL}/spin-the-wheel/background/${response.data.data.background.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }),
        );
      }
      if (!response.data.data.background.image) {
        dispatch(setBackgroundImg(""));
        dispatch(
          setStyle({
            backgroundColor: response.data.data.background.hexCode,
          }),
        );
      }
      dispatch(setLoader(true));
    } catch (error) {
      console.error("Error fetching data:", error);
      // Dispatch an action to handle the error state in Redux if needed
    } finally {
      dispatch(setLoader(false)); // Set loader to false after fetching data
    }
  };

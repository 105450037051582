import { useEffect, useState } from "react";
import { parseISO, subDays } from "date-fns";
import Game from "../Components/Game";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import BuiltWithWorkplay from "../Components/BuiltWithWorkplay";
import LinkActiveOrExpiredPage from "./LinkActiveOrExpiredPage";
const { REACT_APP_S3_URL } = process.env;

interface PlayProps {}

const Play: React.FC<PlayProps> = ({}) => {
  const [isMounted, setIsMounted] = useState(false);

  const { start, end, title, FaviconUrl } = useSelector(
    (state: RootState) => state.customize,
  );

  const parseStringDate = (dateString: string): Date => {
    return parseISO(dateString);
  };
  const currentDate = new Date();
  const originalStart = parseStringDate(start);
  const newStart = subDays(originalStart, 1);
  const isBeforeEvent = currentDate < new Date(newStart);
  const isAfterEvent = currentDate > new Date(end);
  const adjustedStartDate = subDays(new Date(originalStart), 1);
  const adjustedEndDate = subDays(new Date(end), 1);

  useEffect(() => {
    setIsMounted(true);
    document.body.style.overflow = "hidden";
    document.title = title;
    const link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${REACT_APP_S3_URL}/spin-the-wheel/favicon/${FaviconUrl}`;
    }
  }, []);

  return isAfterEvent || isBeforeEvent ? (
    <LinkActiveOrExpiredPage
      message={
        isBeforeEvent
          ? "This link is not active yet"
          : "This link is no longer active."
      }
      additionalInfo={
        isBeforeEvent
          ? `You can access this link only between ${new Date(adjustedStartDate).toLocaleString()} and ${new Date(adjustedEndDate).toLocaleString()}.`
          : "This link is no longer active."
      }
    />
  ) : (
    <div
      style={{
        opacity: isMounted ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
      }}
      className="flex h-screen flex-col items-center justify-center"
    >
      <Game isCutomize={false} />
      <BuiltWithWorkplay />
      <Toaster />
    </div>
  );
};

export default Play;

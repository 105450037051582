import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Segment {
  id: string;
  prize: string;
  weight: number;
  color: string;
  textColor: string;
  isWin: boolean;
}

interface User {
  name: string;
  email: string;
  prize: string;
}

interface Instruction {
  id: number;
  text: string;
}

interface Style {
  [key: string]: string | number | boolean; // Allow dynamic style properties
}

interface WheelState {
  segments: Segment[];
  fontSize: string;
  fontFamily: string;
  showWheelPopup: boolean;
  isdone: boolean;
  spinning: boolean;
  selected: object;
  backgroundColor: string;
  backgroundIMG: string;
  logo: string;
  LogoUrl: string;
  CampaignID: string;
  GameID: string;
  token: string;
  preview: boolean;
  collectUserInfo: boolean;
  user: User;
  loader: boolean;
  userId: string;
  title: string;
  description: string;
  instructions: Instruction[];
  currentScreen: number;
  instruction: boolean;
  textColor: string;
  secondaryColor: string;
  start: string;
  end: string;
  style: Style;
  FavIcon: boolean;
  FaviconUrl: string;
}

const initialState: WheelState = {
  segments: [
    {
      id: "1",
      prize: "40% off",
      weight: 1,
      color: "#003770",
      textColor: "#ffffff",
      isWin: true,
    },
    {
      id: "2",
      prize: "60% off",
      weight: 1,
      color: "#f8b214",
      textColor: "#ffffff",
      isWin: true,
    },
    {
      id: "3",
      prize: "10% off",
      weight: 1,
      color: "#f94b26",
      textColor: "#ffffff",
      isWin: true,
    },
    {
      id: "4",
      prize: "20% off",
      weight: 1,
      color: "#003770",
      textColor: "#ffffff",
      isWin: true,
    },
    {
      id: "5",
      prize: "90% off",
      weight: 1,
      color: "#f8b214",
      textColor: "#ffffff",
      isWin: true,
    },
    {
      id: "6",
      prize: "No luck today",
      weight: 1,
      color: "#f94b26",
      textColor: "#ffffff",
      isWin: false,
    },
  ],
  fontSize: "16",
  fontFamily: "Trebuchet MS",
  showWheelPopup: false,
  isdone: false,
  spinning: false,
  selected: {},
  backgroundColor: "#f94b26",
  backgroundIMG: "none",
  logo: "",
  LogoUrl: "",
  CampaignID: "",
  GameID: "",
  token: "",
  preview: false,
  collectUserInfo: false,
  user: { name: "", email: "", prize: "" },
  loader: false,
  userId: "",
  title: "Spin the Wheel",
  description: "It's simple, fun, and filled with surprises!",
  instructions: [],
  currentScreen: 0,
  instruction: false,
  textColor: "black",
  secondaryColor: "#f69b3a",
  start: "",
  end: "",
  style: { backgroundColor: "#2dd2d0" },
  FavIcon: false,
  FaviconUrl: "",
};

const Customize = createSlice({
  name: "wheel",
  initialState,
  reducers: {
    setSegments(state, action: PayloadAction<Segment[]>) {
      state.segments = action.payload;
    },
    setFontSize(state, action: PayloadAction<string>) {
      state.fontSize = action.payload;
    },
    setFontFamily(state, action: PayloadAction<string>) {
      state.fontFamily = action.payload;
    },
    setShowWheelPopup(state, action: PayloadAction<boolean>) {
      state.showWheelPopup = action.payload;
    },
    setDone(state, action: PayloadAction<boolean>) {
      state.isdone = action.payload;
    },
    setSpinning(state, action: PayloadAction<boolean>) {
      state.spinning = action.payload;
    },
    setSelected(state, action: PayloadAction<object>) {
      state.selected = action.payload;
    },
    setBackgroundColor(state, action: PayloadAction<string>) {
      state.backgroundColor = action.payload;
    },
    setBackgroundImg(state, action: PayloadAction<string>) {
      state.backgroundIMG = action.payload;
    },
    setLogo(state, action: PayloadAction<string>) {
      state.logo = action.payload;
    },
    setLogoUrl(state, action: PayloadAction<string>) {
      state.LogoUrl = action.payload;
    },
    setCampaignID(state, action: PayloadAction<string>) {
      state.CampaignID = action.payload;
    },
    setGameID(state, action: PayloadAction<string>) {
      state.GameID = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setPreview(state, action: PayloadAction<boolean>) {
      state.preview = action.payload;
    },
    setCollectUserInfo(state, action: PayloadAction<boolean>) {
      state.collectUserInfo = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setLoader(state, action: PayloadAction<boolean>) {
      state.loader = action.payload;
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    setInstructions(state, action: PayloadAction<Instruction[]>) {
      state.instructions = action.payload;
    },
    setCurrentScreen(state, action: PayloadAction<number>) {
      state.currentScreen = action.payload;
    },
    setInstruction(state, action: PayloadAction<boolean>) {
      state.instruction = action.payload;
    },
    setTextColor(state, action: PayloadAction<string>) {
      state.textColor = action.payload;
    },
    setSecondaryColor(state, action: PayloadAction<string>) {
      state.secondaryColor = action.payload;
    },
    setStart(state, action: PayloadAction<string>) {
      state.start = action.payload;
    },
    setEnd(state, action: PayloadAction<string>) {
      state.end = action.payload;
    },
    setStyle(state, action: PayloadAction<Style>) {
      state.style = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    setFavIcon(state, action: PayloadAction<boolean>) {
      state.FavIcon = action.payload;
    },
    setFaviconUrl(state, action: PayloadAction<string>) {
      state.FaviconUrl = action.payload;
    },
  },
});

export const {
  setSegments,
  setFontSize,
  setFontFamily,
  setShowWheelPopup,
  setDone,
  setSpinning,
  setSelected,
  setBackgroundColor,
  setBackgroundImg,
  setLogo,
  setLogoUrl,
  setCampaignID,
  setToken,
  setPreview,
  setCollectUserInfo,
  setUser,
  setLoader,
  setUserId,
  setTitle,
  setDescription,
  setInstructions,
  setCurrentScreen,
  setInstruction,
  setTextColor,
  setSecondaryColor,
  setStart,
  setEnd,
  setStyle,
  setGameID,
  resetState,
  setFavIcon,
  setFaviconUrl,
} = Customize.actions;

export default Customize.reducer;
